import { Link } from "gatsby"
import * as React from "react"
import Button from "../components/button"
import H1 from "../components/h1"
import Layout from "../components/layout"
import Seo from "../components/seo"

export default function ProjectQuestionnaireSubmittedPage() {
  return (
    <Layout>
      <Seo title="Project Questionnaire Submitted" />
      <section className="py-8 px-8 lg:px-16">
        <H1 className="text-center">Project Details Received!</H1>

        <p className="py-4 italic text-lg text-center font-semibold">
          Thank you for sharing additional project details. This will help us
          generate a more accurate estimate.
        </p>

        <p className="py-4 italic text-lg text-center font-semibold">
          Your request will be reviewed and we will send you a response via
          email.
        </p>

        <Link to="/" className="block text-center">
          <Button>Back to Home</Button>
        </Link>
      </section>
    </Layout>
  )
}
